import { API_URL, Order, customFetch } from '../..';
import { COOKIE_ORDER_ID } from '../../../components/constants';
import { getCookie } from '../../../utils';

/**
 *
 * @param lineId lineId to be updated
 * @param comment comment to be updated
 */
export const updateProductCommentToOrder = async (lineId: string, comment: string): Promise<Order> => {
    const orderId = getCookie(COOKIE_ORDER_ID);
    const url = `${API_URL}/suites/orders/${orderId}/products/comment`;

    try {
        const response = await customFetch(url, {
            method: 'PATCH',
            headers: {
                accept: '*/*',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ lineId, comment })
        });

        return response;
    } catch (error) {
        throw new Error(`HTTP error: ${error}`);
    }
};
