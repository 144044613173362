import React, { useEffect, useState, useMemo } from 'react';
import { CUSTOM_EVENT_EMPTY_CATEGORY, URL_IMAGES, COOKIE_ORDER_ID } from '../../components/constants';
import { Product, CartLine } from '../../services';
import { useCategory, useOrderManagement } from '../../context';
import { useFetchProducts } from '../../hooks';
import { getCookie, transformUrl } from '../../utils';
import ServiceFeeDisclaimer from '../../components/service-fee-disclaimer';
import { defaultFontFamily } from '../../styles';
import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import { DividerIcon } from './divider-icon';
import { DisabledRemoveIcon } from './disabled-remove-icon';
import { RemoveIcon } from './remove-icon';
import { AddIcon } from './add-icon';

type CategoryProductsPageProps = {
    categoryName: string;
    categoryDescription: string;
    categoryId: number;
    navigateToItem: (product: Product) => void;
};

export const CategoryProductsPage = ({ categoryId, categoryName, categoryDescription, navigateToItem }: CategoryProductsPageProps) => {
    const {
        order,
        isOrderLoading,
        createOrderAndAddProductToOrder,
        addProduct: addProductFromContext,
        updateProducts,
        deleteProducts
    } = useOrderManagement();
    const { filteredSubCategories, handleSubCategorySelection } = useCategory();
    const [subCategory, setSubCategory] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { products, error: productError, isLoading: isProductLoading } = useFetchProducts(subCategory);

    const handleCategoryClick = (subCategoryId: number) => {
        setSubCategory(subCategoryId);
    };

    const handleError = (e: any) => {
        e.target.src =
            'https://images-us-prod.cms.commerce.dynamics.com/cms/api/skhrmstldd/imageFileData/search?fileName=/Products/Z0209_000_001.png';
        //https://publish-p111705-e1190076.adobeaemcloud.com/content/dam/intuit-dome/en/mobile/fan-app/suites/assets/MenuItemsPlaceholderImage.png
    };

    const itemsCount = useMemo(() => {
        return order?.cart?.cartLines.reduce((total: number, cartLine: CartLine) => total + cartLine.quantity, 0) ?? 0;
    }, [order?.cart?.cartLines]);

    useEffect(() => {
        const subCategoryList = handleSubCategorySelection(categoryId, subCategory);
        setSubCategory(subCategoryList.find(category => category.selected)?.categoryId || categoryId);
    }, [subCategory]);

    const getOrderProductQuantity = (productId: string) => {
        const product = order?.cart.cartLines.find(product => product.itemId === productId);
        return product ? product.quantity : 0;
    };

    const getOrderProductLineId = (productId: string) => {
        return order?.cart.cartLines.find(product => product.itemId === productId)?.lineId;
    };

    const addProduct = async (product: Product, productQuantity: number) => {
        setIsLoading(true);
        try {
            if (getCookie(COOKIE_ORDER_ID)) {
                await addProductFromContext(product, productQuantity, '');
            } else {
                await createOrderAndAddProductToOrder(product, productQuantity, '');
            }
        } catch (error) {
            console.error('Failed to add product to order:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const removeProduct = async (lineId: string | undefined) => {
        await deleteProducts([{ lineId }]);
    };

    const updateProduct = async (lineId: string | undefined, quantity: number) => {
        await updateProducts([{ lineId, quantity }]);
    };

    const renderProductsList = () =>
        products?.map((product: Product) => (
            <li id={`product-${product.productId}`} key={product.productId} style={{ marginBottom: '16px' }}>
                <div style={{ display: 'flex', alignItems: 'flex-start', gap: '12px' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '4px', marginBottom: '30px' }}>
                        <p style={{ fontWeight: 'bold', margin: '0px', marginBottom: '4px', lineHeight: '24px' }}>
                            {!!product.name ? product.name : '-'}
                        </p>
                        <p style={{ fontSize: '14px', margin: '0px', marginBottom: '4px', lineHeight: '20px' }}>
                            {!!product.description ? product.description : '-'}
                        </p>
                        <button
                            onClick={() => {
                                navigateToItem(product);
                            }}
                            style={{
                                background: 'white',
                                border: 'none',
                                padding: '0',
                                marginRight: 'auto'
                            }}
                        >
                            <p
                                style={{
                                    ...defaultFontFamily,
                                    fontWeight: 700,
                                    fontSize: '14px',
                                    lineHeight: '20px',
                                    textDecoration: 'underline',
                                    margin: '0'
                                }}
                            >
                                View more
                            </p>
                        </button>
                    </div>
                    <div style={{ marginLeft: 'auto' }}>
                        <img
                            src={transformUrl(`${URL_IMAGES}${product.primaryImageUrl}`)}
                            alt={product.description}
                            style={{ width: '88px', height: 'auto' }}
                            onError={e => handleError(e)}
                        />
                    </div>
                </div>
                <div style={{ display: 'flex' }}>
                    <p style={{ fontWeight: 'bold', fontSize: '16px', margin: 'auto 0' }}>${product.price.toFixed(2)}</p>
                    <div style={{ display: 'flex', gap: '16px', marginLeft: 'auto', minWidth: '140px' }}>
                        <button
                            style={{
                                border: 'none',
                                background: 'transparent',
                                cursor: 'pointer',
                                margin: 0,
                                padding: 0
                            }}
                            aria-label='Decrease Item'
                            disabled={getOrderProductQuantity(product.productId) === 0}
                            onClick={
                                getOrderProductQuantity(product.productId) === 1
                                    ? () => removeProduct(getOrderProductLineId(product.productId))
                                    : () =>
                                          updateProduct(
                                              getOrderProductLineId(product.productId),
                                              getOrderProductQuantity(product.productId) - 1
                                          )
                            }
                        >
                            {getOrderProductQuantity(product.productId) === 0 ? <DisabledRemoveIcon /> : <RemoveIcon />}
                        </button>
                        <p style={{ fontWeight: 600, fontSize: '32px', margin: 0 }}>{getOrderProductQuantity(product.productId)}</p>
                        <button
                            style={{
                                border: 'none',
                                background: 'transparent',
                                cursor: 'pointer',
                                margin: 0,
                                padding: 0
                            }}
                            aria-label='Increase Item'
                            onClick={
                                getOrderProductQuantity(product.productId) === 0
                                    ? () => addProduct(product, getOrderProductQuantity(product.productId) + 1)
                                    : () =>
                                          updateProduct(
                                              getOrderProductLineId(product.productId),
                                              getOrderProductQuantity(product.productId) + 1
                                          )
                            }
                        >
                            <AddIcon />
                        </button>
                    </div>
                </div>
                <DividerIcon />
            </li>
        ));

    if (isProductLoading || isLoading) {
        return (
            <div className='over-spinner'>
                <img title='loader' className='spinner' />
            </div>
        );
    }

    return (
        <>
            <section>
                <h2 className='header-title' style={{ margin: '8px 24px' }}>
                    {categoryName ?? '-'}
                </h2>
                {categoryDescription ? (
                    <span style={{ ...defaultFontFamily, fontSize: '16px', lineHeight: '24px', margin: '8px 24px' }}>
                        {categoryDescription}
                    </span>
                ) : null}
                {filteredSubCategories.length > 1 ? (
                    <ScrollMenu>
                        {filteredSubCategories.map(({ name, categoryId, selected }) => (
                            <button
                                key={categoryId}
                                onClick={() => {
                                    handleCategoryClick(categoryId);
                                }}
                                style={{ background: 'white', border: 'none', margin: '20px 10px 16px 10px' }}
                            >
                                <p
                                    style={{
                                        ...defaultFontFamily,
                                        fontSize: '16px',
                                        lineHeight: '24px',
                                        whiteSpace: 'nowrap',
                                        margin: '0',
                                        marginBottom: '10px'
                                    }}
                                    className={selected ? 'selected' : ''}
                                >
                                    {name}
                                </p>
                            </button>
                        ))}
                    </ScrollMenu>
                ) : null}
                {products?.length ? <ul style={{ listStyleType: 'none', padding: '8px 24px' }}>{renderProductsList()}</ul> : null}
                {productError === CUSTOM_EVENT_EMPTY_CATEGORY ? (
                    <div id={CUSTOM_EVENT_EMPTY_CATEGORY} style={{ margin: '24px 20px', textAlign: 'center' }}>
                        <p>There are no products in this category</p>
                    </div>
                ) : null}
                {productError && productError !== CUSTOM_EVENT_EMPTY_CATEGORY ? (
                    <div style={{ margin: '24px 20px', textAlign: 'center' }}>
                        <p>There was an error loading your products . Please try again.</p>
                    </div>
                ) : null}
            </section>
            <div style={{ paddingBottom: !isOrderLoading && order && itemsCount > 0 ? '115px' : '0px' }}>
                <ServiceFeeDisclaimer />
            </div>
        </>
    );
};
