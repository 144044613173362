import { getCookie } from '../../../utils';
import { API_URL } from '../../constants';
import { customFetch } from '../../custom-fetch';
import { Order } from '../get-order';

export const suspendOrder = async (): Promise<Order | undefined> => {
    const orderId = getCookie('orderId');
    const url = `${API_URL?.replace('/v1', '/v2')}/suites/orders/${orderId}/suspend`;
    const requestBody = {};

    try {
        const response = await customFetch(url, {
            method: 'POST',
            headers: {
                accept: '*/*',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestBody)
        });

        return response;
    } catch (error) {
        console.error('Failed to suspend order:', error);
        return undefined;
    }
};
