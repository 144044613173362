import React from 'react';
import { useConfig } from '../../context';
import { defaultFontFamily } from '../../styles';

export const BackNavigation = () => {
    const { isNewNavigation } = useConfig();

    return isNewNavigation ? (
        <></>
    ) : (
        <div style={{ marginBottom: '24px' }}>
            <button
                onClick={() => history.back()}
                style={{
                    ...defaultFontFamily,
                    display: 'flex',
                    border: 'none',
                    background: 'transparent',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    fontSize: '16px',
                    marginBottom: '12px'
                }}
            >
                &lt; Back
            </button>
        </div>
    );
};
