import React from 'react';

export const CollapseIcon = () => (
    <svg xmlns='http://www.w3.org/2000/svg' width='18' height='12' viewBox='0 0 18 12' fill='none'>
        <path
            d='M9.00002 4.43711L2.40002 11.0371L0.514685 9.15177L9.00002 0.666441L17.4854 9.15178L15.6 11.0371L9.00002 4.43711Z'
            fill='#121212'
        />
    </svg>
);
