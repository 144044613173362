import { API_URL, customFetch } from '../..';
import { COOKIE_EVENT_ID, COOKIE_IDENTITY_ID, COOKIE_LOCATION_ID, COOKIE_PACKAGE_ID } from '../../../components/constants';
import { getCookie } from '../../../utils';
import { CreateOrderResponse } from './types';

/**
 * Creates a new order with the cookie information.
 * @yields data from https://portalsta.lacdev.cloud/api-details#api=commerce&operation=createOrder
 */
export const createOrder = async (): Promise<CreateOrderResponse | undefined> => {
    const identityId = getCookie(COOKIE_IDENTITY_ID);
    const url = `${API_URL?.replace('/v1', '/v2')}/suites/orders/${identityId}`;

    const eventId = getCookie(COOKIE_EVENT_ID);
    const packageId = getCookie(COOKIE_PACKAGE_ID) ?? 0;
    const locationId = getCookie(COOKIE_LOCATION_ID);

    if (!packageId) {
        console.warn('COOKIE_PACKAGE_ID is not set, setting it as 0');
    }

    const requestData = {
        eventId,
        packageId,
        locationId
    };

    const options: RequestInit = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestData)
    };

    try {
        const response = await customFetch(url, options);

        const data: CreateOrderResponse = await response;
        return data;
    } catch (error) {
        console.error('Failed to create order:', error);
        return undefined;
    }
};
