import React, { createContext, useContext, useState, ReactNode, useCallback, useEffect } from 'react';
import { COOKIE_CONFIG_SERVICE, COOKIE_MOBILE_VERSION } from '../components/constants';
import { ConfigValue } from '../services/config-service/types';
import { getConfigService } from '../services';
import { getCookie } from '../utils';
import { compareVersions } from 'compare-versions';

interface ConfigContextProps {
    isNewNavigation: boolean;
}

const ConfigContext = createContext<ConfigContextProps | undefined>(undefined);

export const useConfig = () => {
    const context = useContext(ConfigContext);
    if (!context) {
        throw new Error('useConfig must be used within a ConfigProvider');
    }
    return context;
};

export const ConfigProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [isNewNavigation, setIsNewNavigation] = useState<boolean>(false);
    const configServiceUrl = getCookie(COOKIE_CONFIG_SERVICE) || '';
    const mobileVersion = getCookie(COOKIE_MOBILE_VERSION);

    const fetchConfigService = useCallback(async () => {
        try {
            const response = await getConfigService(configServiceUrl);
            if (!Array.isArray(response)) {
                throw new Error('Failed to fetch config service.');
            }
            const commerceConfig: ConfigValue = response.find(config => config.label === 'suites.ecommerce.config').value;
            if (commerceConfig && mobileVersion && commerceConfig.enableNewMenuNavigationVersion) {
                setIsNewNavigation(compareVersions(mobileVersion, commerceConfig.enableNewMenuNavigationVersion) >= 0);
            }
        } catch (error) {
            console.error('ConfigContext: Failed to fetch config service:', error);
        }
    }, []);

    useEffect(() => {
        if (configServiceUrl && mobileVersion) {
            void fetchConfigService();
        }
    }, []);

    return (
        <ConfigContext.Provider
            value={{
                isNewNavigation
            }}
        >
            {children}
        </ConfigContext.Provider>
    );
};
