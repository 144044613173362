import React, { useMemo } from 'react';
import { useOrderManagement } from '../../context';
import { CartLine } from '../../services';
import { numberToCurrentcy } from '../../utils';
import { defaultFontFamily } from '../../styles';

interface OrderBarProps {
    navigateToCart?: () => void;
}

export const OrderBar = ({ navigateToCart }: OrderBarProps) => {
    const { order, isOrderLoading } = useOrderManagement();

    const itemsCount = useMemo(() => {
        return order?.cart?.cartLines.reduce((total: number, cartLine: CartLine) => total + cartLine.quantity, 0) ?? 0;
    }, [order?.cart?.cartLines]);

    const total = useMemo(() => {
        return order?.cart.netPrice;
    }, [order?.cart]);

    return !isOrderLoading && navigateToCart && order && itemsCount > 0 ? (
        <div
            style={{
                position: 'fixed',
                bottom: 0,
                left: 0,
                right: 0,
                padding: '24px',
                background: '#FFFFFF',
                display: 'flex',
                justifyContent: 'space-between',
                boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.5)'
            }}
        >
            {
                <>
                    <div style={{ display: 'grid' }}>
                        <span style={{ ...defaultFontFamily, fontSize: '14px', fontWeight: 400 }}>{`Total: ${itemsCount} items`}</span>
                        <span style={{ ...defaultFontFamily, fontSize: '24px', fontWeight: 700 }}>${numberToCurrentcy(total || 0)}</span>
                    </div>
                    <button
                        aria-label={'View order'}
                        style={{
                            ...defaultFontFamily,
                            background: 'black',
                            borderRadius: '20px 0 20px 0',
                            color: 'white',
                            padding: '12px',
                            textDecoration: 'none',
                            textTransform: 'uppercase',
                            width: '164px',
                            height: '48px',
                            textAlign: 'center',
                            cursor: 'pointer',
                            fontSize: '16px',
                            fontWeight: 600
                        }}
                        onClick={() => navigateToCart()}
                    >
                        <span
                            style={{
                                marginLeft: '5px',
                                textTransform: 'uppercase'
                            }}
                        >
                            Go to Order
                        </span>
                    </button>
                </>
            }
        </div>
    ) : (
        <></>
    );
};
