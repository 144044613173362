import React from 'react';
import { CategoryProvider, NavigationProvider, OrderManagementProvider, ConfigProvider } from './context';
import { RouteSwitcher } from './pages';

const App = () => (
    <>
        <OrderManagementProvider>
            <ConfigProvider>
                <CategoryProvider>
                    <NavigationProvider>
                        <RouteSwitcher />
                    </NavigationProvider>
                </CategoryProvider>
            </ConfigProvider>
        </OrderManagementProvider>
    </>
);

export default App;
