import React from 'react';

export const ExpandIcon = () => (
    <svg xmlns='http://www.w3.org/2000/svg' width='18' height='12' viewBox='0 0 18 12' fill='none'>
        <path
            d='M8.99998 7.56289L15.6 0.962891L17.4853 2.84822L8.99998 11.3336L0.514648 2.84822L2.39998 0.962891L8.99998 7.56289Z'
            fill='#121212'
        />
    </svg>
);
