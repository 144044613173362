import React from 'react';
import { Order } from '../../services';
import { defaultFontFamily } from '../../styles';
import { numberToCurrentcy } from '../../utils';

interface OrderSummaryProps {
    order: Order;
}

const OrderSummary: React.FC<OrderSummaryProps> = ({ order }) => {
    return (
        <>
            <h2 style={{ ...defaultFontFamily, fontSize: '20px', fontWeight: 700 }}>Order Summary</h2>
            <div
                style={{
                    ...defaultFontFamily,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    margin: '24px 0'
                }}
            >
                <div style={{ ...defaultFontFamily, fontSize: '14px', fontWeight: 400 }}>Subtotal</div>
                <div style={{ ...defaultFontFamily, fontSize: '14px', fontWeight: 600 }}>${numberToCurrentcy(order?.cart.netPrice)}</div>
            </div>
            <div
                style={{
                    ...defaultFontFamily,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    margin: '24px 0'
                }}
            >
                <div style={{ ...defaultFontFamily, fontSize: '14px', fontWeight: 400 }}>Service Charge</div>
                <div style={{ ...defaultFontFamily, fontSize: '14px', fontWeight: 600 }}>
                    ${numberToCurrentcy(order?.cart.serviceChargeAmount) ?? '--'}
                </div>
            </div>
            <div
                style={{
                    ...defaultFontFamily,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    margin: '24px 0'
                }}
            >
                <div style={{ ...defaultFontFamily, fontSize: '14px', fontWeight: 400 }}>Taxes</div>
                <div style={{ ...defaultFontFamily, fontSize: '14px', fontWeight: 600 }}>
                    ${numberToCurrentcy(order?.cart.taxAmount) ?? '--'}
                </div>
            </div>
            {order?.tipAmount ? (
                <div
                    style={{
                        ...defaultFontFamily,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        margin: '24px 0'
                    }}
                >
                    <div style={{ ...defaultFontFamily, fontSize: '14px', fontWeight: 400 }}>Tip</div>
                    <div style={{ ...defaultFontFamily, fontSize: '14px', fontWeight: 600 }}>
                        ${numberToCurrentcy(order?.tipAmount) ?? '--'}
                    </div>
                </div>
            ) : null}
            <div
                style={{
                    ...defaultFontFamily,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    margin: '24px 0'
                }}
            >
                <div
                    style={{
                        ...defaultFontFamily,
                        fontSize: '14px',
                        fontWeight: 700,
                        margin: '0 0 24px 0'
                    }}
                >
                    Total
                </div>
                <div
                    style={{
                        ...defaultFontFamily,
                        fontWeight: 600,
                        margin: '0 0 24px 0'
                    }}
                >
                    ${numberToCurrentcy(order?.cart.totalAmount)}
                </div>
            </div>
        </>
    );
};

export default OrderSummary;
