import { customFetch } from '..';
import { Config } from './types';

/**
 *
 * @param url url to be called
 */
export const getConfigService = async (url: string): Promise<Config> => {
    try {
        const response = await customFetch(decodeURIComponent(url), {
            method: 'GET',
            headers: {
                accept: '*/*',
                'Content-Type': 'application/json'
            }
        });
        return response;
    } catch (error) {
        throw new Error(`HTTP error: ${error}`);
    }
};
