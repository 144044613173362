import React, { useState, useMemo, useEffect } from 'react';
import { useOrderManagement } from '../../context';
import { MenuType, CartLine } from '../../services';
import { getCookie, transformUrl, numberToCurrentcy } from '../../utils';
import { COOKIE_ORDER_ID, URL_IMAGES, COOKIE_MENU_TYPE } from '../../components/constants';
import ServiceFeeDisclaimer from '../../components/service-fee-disclaimer';
import { defaultFontFamily } from '../../styles';
import { DisabledRemoveIcon } from '../category-products-page/disabled-remove-icon';
import { RemoveIcon } from '../category-products-page/remove-icon';
import { AddIcon } from '../category-products-page/add-icon';
import { CollapseIcon } from './collapse-icon';
import { ExpandIcon } from './expand-icon';
import { DividerCommentIcon } from './divider-comment-icon';

type ProductDetailPageProps = {
    navigateToCategoryProductsPage: (categoryId?: number) => void;
};

export const ProductDetailPage = ({ navigateToCategoryProductsPage }: ProductDetailPageProps) => {
    const [productQuantity, setQuantity] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [comment, setComment] = useState<string>('');
    const [initialComment, setInitialComment] = useState<string>('');
    const [isExpanded, setIsExpanded] = useState<boolean>(false);
    const [isEdited, setIsEdited] = useState<boolean>(false);
    const [initialQuantity, setinitialQuantity] = useState<number>(0);
    const {
        createOrderAndAddProductToOrder,
        isOrderLoading,
        addProduct: addProductFromContext,
        product,
        order,
        updateProducts,
        updateProductComment,
        deleteProducts
    } = useOrderManagement();

    const MAX_COMMENT_LENGTH = 500;
    const commentLength = comment.length;
    const isOverLimit = commentLength > MAX_COMMENT_LENGTH;
    const menuType: MenuType = getCookie(COOKIE_MENU_TYPE) as MenuType;

    const itemsCount = useMemo(() => {
        return order?.cart?.cartLines.reduce((total: number, cartLine: CartLine) => total + cartLine.quantity, 0) ?? 0;
    }, [order?.cart?.cartLines]);

    const total = useMemo(() => {
        return order?.cart.netPrice;
    }, [order?.cart]);

    useMemo(() => {
        const productOrder = order?.cart.cartLines.find(cartLine => cartLine.itemId === product?.productId);
        const specialRequest = productOrder?.attributeValues?.find(attribute => attribute.Name === 'Special_Request');
        const quantity = product && productOrder ? productOrder.quantity : 0;
        const productComment = product && specialRequest ? specialRequest.TextValue : '';
        setQuantity(quantity);
        setinitialQuantity(quantity);
        setComment(productComment);
        setInitialComment(productComment);
        setIsExpanded(!!productComment);
    }, [order?.cart?.cartLines, product]);

    useEffect(() => {
        if (initialQuantity !== productQuantity) {
            setIsEdited(true);
        } else {
            setIsEdited(false);
        }
    }, [productQuantity]);

    const handleBack = () => {
        history.back();
    };

    const handleQuantityChange = (action: 'add' | 'subtract') => {
        setQuantity(prevQuantity => {
            if (action === 'add') {
                return prevQuantity + 1;
            } else if (action === 'subtract') {
                return prevQuantity > 0 ? prevQuantity - 1 : 0;
            }
            return prevQuantity;
        });
    };

    const handleExpandCollapse = () => {
        setIsExpanded(!isExpanded);
    };

    const handleCommentChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setComment(event.target.value);
        setIsEdited(initialComment !== event.target.value);
    };

    if (!product) {
        return <></>;
    }

    const getOrderProductLineId = () => {
        return order?.cart.cartLines.find(cartLine => cartLine.itemId === product.productId)?.lineId;
    };

    const saveChanges = async () => {
        setIsLoading(true);
        try {
            if (getCookie(COOKIE_ORDER_ID)) {
                const lineId = getOrderProductLineId();
                if (lineId) {
                    if (productQuantity === 0) {
                        await deleteProducts([{ lineId }]);
                    } else if (productQuantity !== initialQuantity) {
                        await updateProducts([{ lineId, quantity: productQuantity }]);
                    }
                    if (initialComment !== comment) {
                        await updateProductComment(lineId, comment);
                    }
                } else {
                    await addProductFromContext(product, productQuantity, comment);
                }
            } else {
                await createOrderAndAddProductToOrder(product, productQuantity, comment);
            }
        } catch (error) {
            console.error('Failed to add product to order:', error);
        } finally {
            handleBack();
        }
    };

    return (
        <>
            <section style={{ marginBottom: '24px' }}>
                <div key={product?.productId}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <img
                            src={transformUrl(`${URL_IMAGES}${product.primaryImageUrl}`)}
                            alt={product.name}
                            style={{ width: '100%', height: 'auto', marginBottom: '32px' }}
                        />
                        <div style={{ ...defaultFontFamily, alignSelf: 'normal', margin: '0px 24px' }}>
                            <span className='header-title' style={{ display: 'block', marginBottom: '24px' }}>
                                {!!product.name ? product.name : '-'}
                            </span>
                            <span style={{ fontSize: '20px', fontWeight: 600, display: 'block', marginBottom: '18px' }}>
                                ${product.price.toFixed(2)}
                            </span>
                            <span style={{ display: 'block', marginBottom: '24px', fontSize: '14px' }}>{product?.description ?? '-'}</span>
                            <div style={{ marginBottom: '16px' }}>
                                <label
                                    htmlFor='productQuantity'
                                    style={{
                                        ...defaultFontFamily,
                                        fontWeight: 700,
                                        fontSize: '14px',
                                        marginBottom: '12px',
                                        display: 'block'
                                    }}
                                >
                                    Quantity
                                </label>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: '', gap: '12px' }}>
                                    <button
                                        style={{
                                            border: 'none',
                                            background: 'transparent',
                                            cursor: 'pointer',
                                            margin: 0,
                                            padding: 0
                                        }}
                                        aria-label='Decrease Item'
                                        disabled={isOrderLoading || productQuantity === 0}
                                        onClick={() => handleQuantityChange('subtract')}
                                    >
                                        {isOrderLoading || productQuantity === 0 ? <DisabledRemoveIcon /> : <RemoveIcon />}
                                    </button>
                                    <p style={{ fontWeight: 600, fontSize: '32px', margin: 0 }}>{productQuantity}</p>
                                    <button
                                        style={{
                                            border: 'none',
                                            background: 'transparent',
                                            cursor: 'pointer',
                                            margin: 0,
                                            padding: 0
                                        }}
                                        aria-label='Increase Item'
                                        onClick={() => handleQuantityChange('add')}
                                    >
                                        <AddIcon />
                                    </button>
                                </div>
                            </div>
                            {menuType === 'PRE' && productQuantity > 0 ? (
                                <div
                                    style={{
                                        padding: '24px',
                                        marginTop: '32px',
                                        backgroundColor: '#EFEFED',
                                        boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.2)'
                                    }}
                                >
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <label
                                            htmlFor='comment'
                                            style={{
                                                ...defaultFontFamily,
                                                fontWeight: 700,
                                                fontSize: '16px',
                                                display: 'block'
                                            }}
                                        >
                                            Any allergies or special needs?
                                        </label>
                                        <button
                                            style={{
                                                border: 'none',
                                                background: 'transparent',
                                                cursor: 'pointer',
                                                margin: 0,
                                                padding: 0
                                            }}
                                            aria-label={isExpanded ? 'collapse comments' : 'expand comments'}
                                            onClick={() => handleExpandCollapse()}
                                        >
                                            {isExpanded ? <CollapseIcon /> : <ExpandIcon />}
                                        </button>
                                    </div>
                                    {isExpanded ? (
                                        <>
                                            <div style={{ marginTop: '24px' }}>
                                                <DividerCommentIcon />
                                            </div>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    backgroundColor: '#D5DFEC',
                                                    borderBottom: 'solid 2px #2F619E',
                                                    marginTop: '12px',
                                                    padding: '8px'
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        color: '#2F619E',
                                                        fontWeight: 600,
                                                        fontSize: '12px',
                                                        textTransform: 'uppercase'
                                                    }}
                                                >
                                                    Specify your needs
                                                </span>
                                                <textarea
                                                    id='comment'
                                                    value={comment}
                                                    onChange={handleCommentChange}
                                                    style={{
                                                        ...defaultFontFamily,
                                                        fontSize: '16px',
                                                        border: 'none',
                                                        height: '70px',
                                                        resize: 'none',
                                                        backgroundColor: '#D5DFEC'
                                                    }}
                                                />
                                            </div>
                                            <span style={{ color: isOverLimit ? 'red' : 'gray', fontWeight: 400, fontSize: '14px' }}>
                                                {commentLength}/{MAX_COMMENT_LENGTH}
                                            </span>
                                        </>
                                    ) : null}
                                </div>
                            ) : null}
                            {!isOrderLoading && (isEdited || (order && itemsCount > 0)) ? (
                                <div
                                    style={{
                                        position: 'fixed',
                                        bottom: 0,
                                        left: 0,
                                        right: 0,
                                        padding: '24px',
                                        background: '#FFFFFF',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.5)'
                                    }}
                                >
                                    {
                                        <>
                                            <div style={{ display: 'grid' }}>
                                                <span
                                                    style={{ ...defaultFontFamily, fontSize: '14px', fontWeight: 400 }}
                                                >{`Total: ${itemsCount + productQuantity - initialQuantity} items`}</span>
                                                <span style={{ ...defaultFontFamily, fontSize: '24px', fontWeight: 700 }}>
                                                    $
                                                    {numberToCurrentcy(
                                                        total
                                                            ? total + product.price * productQuantity - product.price * initialQuantity
                                                            : product.price * productQuantity
                                                    )}
                                                </span>
                                            </div>
                                            <button
                                                aria-label={'View order'}
                                                style={{
                                                    ...defaultFontFamily,
                                                    background: isEdited ? 'black' : '#BEC0C2',
                                                    borderRadius: '20px 0 20px 0',
                                                    color: isEdited ? 'white' : '#60605F',
                                                    padding: '12px',
                                                    textDecoration: 'none',
                                                    textTransform: 'uppercase',
                                                    width: '164px',
                                                    height: '48px',
                                                    textAlign: 'center',
                                                    cursor: 'pointer',
                                                    fontSize: '16px',
                                                    fontWeight: 600,
                                                    border: 'none'
                                                }}
                                                disabled={isLoading}
                                                onClick={isEdited ? saveChanges : handleBack}
                                            >
                                                <span
                                                    style={{
                                                        marginLeft: '5px',
                                                        textTransform: 'uppercase'
                                                    }}
                                                >
                                                    Save
                                                </span>
                                            </button>
                                        </>
                                    }
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            </section>
            <div style={{ paddingBottom: !isOrderLoading && (isEdited || (order && itemsCount > 0)) ? '115px' : '0px' }}>
                <ServiceFeeDisclaimer />
            </div>
        </>
    );
};
